import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
// flags
import AlgeriaIcon from "../../../assets/icons/algeria.png";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useSiteConfig } from "../../../config/site-config";

const CountrySelect: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { siteConfig } = useSiteConfig();
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="relative inline-block ltrtext-left rtl:text-right"
      ref={dropdownRef}
    >
      <button
        onClick={toggleDropdown}
        className="flex flex-row items-center space-x-2 w-fit px-4 py-2 text-sm font-medium focus:outline-none text-white"
      >
        <img src={AlgeriaIcon} alt="Algeria" className="w-8" />
        <span>{t("regions.algeria")}</span>
        <motion.div
          animate={{ rotate: !isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDownIcon className="w-4 h-4 text-white" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="dropdown"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="absolute ltr:-right-28 rtl:right-0 min-w-72 mb-2 origin-bottom-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
            style={{ bottom: "100%" }}
          >
            <ul className="w-full">
              {siteConfig?.footer?.countries?.map((el, idx) => (
                <li
                  key={idx}
                  className="flex flex-row items-center justify-between space-x-2 px-4 py-2 text-sm font-medium focus:outline-none text-gray-700 cursor-default w-full"
                >
                  <div className="w-fit flex flex-row items-center justify-center space-x-2">
                    <img
                      src={el?.image}
                      alt={el.label}
                      className="w-8 opacity-50"
                    />
                    <span className="opacity-50">{el?.label}</span>
                  </div>
                  <span className="bg-amber-50 text-amber-600 text-xs font-medium mr-2 px-1.5 rounded py-1">
                    {t("helpers.coming_soon")}
                  </span>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CountrySelect;
