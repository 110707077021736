import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LandingPage, PrivacyPolicy } from "./pages";
import { useTranslation } from "react-i18next";
import { useSiteConfig } from "./config/site-config";
import { Helmet } from "react-helmet";
import Hotjar from "@hotjar/browser";
import NotFoundPage from "./pages/not-found/page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/conditiongeneraldutilisation",
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function App() {
  const { i18n } = useTranslation();
  const { siteConfig } = useSiteConfig();
  document.body.dir = i18n.dir();

  const siteId = 5058523;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteConfig.header.title}</title>
        <meta name="description" content={siteConfig.hero.description} />
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
