import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 992px)").matches;
      // const isMobileScreen = window.innerWidth <= 768; // You can adjust this value
      setIsMobile(isMobileScreen);
    };

    handleResize();

    // window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
