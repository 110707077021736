import { useSiteConfig } from "../../../config/site-config";
import { cn } from "../../../lib/utils";
import { useState } from "react";
import PricingCard from "./pricing-card";
import { motion } from "framer-motion";

const Subscriptions = () => {
  const { siteConfig } = useSiteConfig();
  const data = siteConfig.subscriptions;
  const [typeSelected, setTypeSelected] = useState<string>("hybrid");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const currentPricing = data?.types?.find((i) => i.name === typeSelected);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1, // Delay before animation starts
        staggerChildren: 0.1, // Stagger animation for each card
      },
    },
  };

  return (
    <div
      className="w-full bg-gray-light flex justify-center items-center"
      id="offers"
    >
      <div className="container flex flex-col justify-center items-center ">
        <div className="w-full flex flex-col items-center justify-center p-6 py-12 text-center space-y-3 text-xs ">
          {/* <h2 className="text-secondary md:text-lg">{data.title}</h2> */}
          <h2 className="text-secondary font-bold text-xl md:text-2xl lg:text-4xl">
            {data.title}
          </h2>
          <p className="text-gray-400 font-normal text-base lg:text-lg">
            {data.description}
          </p>
        </div>

        <div
          className={cn(
            "w-full flex flow-row items-center justify-center gap-x-3 md:mb-12"
          )}
        >
          {data?.types?.map((type, index) => (
            <button
              key={index}
              onClick={() => setTypeSelected(type.name)}
              className={cn(
                "font-medium rounded-lg px-3 py-2 text-sm",
                typeSelected === type?.name
                  ? "bg-primary text-secondary"
                  : "shadow-inner border border-gray-dark/20 text-gray-dark"
              )}
            >
              {type.title}
            </button>
          ))}
        </div>

        {/* pricing cards */}
        <motion.div
          key={typeSelected}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className={cn(
            "w-full flex flex-col sm:flex-col lg:flex-row xl:flex-row md:flex-row md:justify-center gap-y-6 md:gap-0 items-center mt-12 mb-28"
          )}
        >
          {currentPricing?.items?.map((item, index) => (
            <PricingCard
              index={typeSelected === "hybrid" ? index : 1}
              item={item}
              key={index}
              type={typeSelected}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Subscriptions;
