import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../../lib/utils";

interface AccordionProps {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      className={cn(
        "w-5/6 flex flex-col items-center justify-center cursor-pointer p-6 shadow-md rounded-md",
        isOpen ? "bg-accent" : "bg-white"
      )}
      onClick={onToggle}
    >
      <div className="w-full flex flex-row items-center justify-start gap-x-2">
        {/* <Image src={OutlineHeartIcon} alt={title} className="w-8 h-8" /> */}
        <h3
          className={cn(
            isOpen ? "text-white" : "text-secondary",
            "text-lg md:text-xl font-semibold"
          )}
        >
          {title}
        </h3>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="w-full mt-6"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0, margin: 0 },
            }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-white text-base text-start mt-6">{content}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { Accordion };
