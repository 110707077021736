import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Type for handler function
type Handler = () => void;

// Type for DropdownItem props
interface DropdownItemProps {
  label: string;
  href: string;
  close: () => void;
}

// Hook for handling outside clicks
const useClickOutside = (handler: Handler) => {
  const domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const maybeHandler = (event: MouseEvent) => {
      if (domNode.current && !domNode.current.contains(event.target as Node)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  }, [handler]);

  return domNode;
};

// Dropdown item component
const DropdownItem: React.FC<DropdownItemProps> = ({ label, href, close }) => {
  const { i18n } = useTranslation();

  return (
    <span
      onClick={() => {
        i18n.changeLanguage(href);
        localStorage.setItem("efawtara-language", href);
        close();
      }}
      className="text-body-color dark:text-dark-6 hover:bg-[#F5F7FD] dark:hover:bg-primary/5 hover:text-primary block px-5 py-2 text-base cursor-pointer text-start"
    >
      {label}
    </span>
  );
};

// Main component
const LanguageSwitcher: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const domNode = useClickOutside(() => {
    setDropdownOpen(false);
  });

  const { t } = useTranslation();

  return (
    <div className="relative inline-block text-left w-fit" ref={domNode}>
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={`flex items-center rounded-[5px] px-5 py-[13px] text-base font-medium text-secondary text-nowrap`}
      >
        {t("header.languages.title")}
        <span className="pl-4">
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current"
          >
            <path d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4063 5.65625 17.6875 5.9375C17.9687 6.21875 17.9687 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1563 10.1875 14.25 10 14.25Z" />
          </svg>
        </span>
      </button>
      <div
        className={`shadow-1 dark:shadow-box-dark absolute left-0 z-40 mt-2 w-full rounded-md bg-white dark:bg-dark-2 py-[10px] transition-all ${
          dropdownOpen
            ? "top-full opacity-100 visible"
            : "top-[110%] invisible opacity-0"
        }`}
      >
        <DropdownItem
          label={t("header.languages.values.ar")}
          href="ar"
          close={() => setDropdownOpen(false)}
        />
        <DropdownItem
          label={t("header.languages.values.fr")}
          href="fr"
          close={() => setDropdownOpen(false)}
        />
        <DropdownItem
          label={t("header.languages.values.en")}
          href="en"
          close={() => setDropdownOpen(false)}
        />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
