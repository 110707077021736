import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-screen bg-white flex flex-col items-center justify-center space-y-4">
      <img
        src={require("../../assets/images/illustrations/error.png")}
        alt=""
        className="max-w-[18vw]"
      />
      <h1 className="font-bold text-secondary text-center md:text-start text-xl sm:text-4xl md:text-2xl lg:text-3xl xl:text-4xl">
        {t("404.title")}
      </h1>

      <div className="w-full flex flex-col space-y-4 sm:space-y-0 sm:flex-row items-center justify-center gap-x-2">
        <Link
          to="/"
          className="bg-secondary text-white uppercase rounded-lg p-3 text-sm font-bold rtl:text-lg text-center w-full sm:w-auto"
        >
          {t("404.ctas.go_home")}
        </Link>
        <a
          href="https://app.efawtara.com/company/login"
          className="bg-primary text-secondary uppercase rounded-lg p-3 text-sm font-bold rtl:text-lg text-center w-full sm:w-auto"
        >
          {t("404.ctas.go_login")}
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
