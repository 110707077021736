import TestimonialItem from "./testimonial-item";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialSlider = ({
  testimonials,
}: {
  testimonials: {
    title: string;
    author: string;
    position: string;
    logo: string;
  }[];
}) => {
  return (
    <Carousel
      showArrows={false}
      autoPlay
      infiniteLoop
      emulateTouch
      showIndicators
      showStatus={false}
      // showThumbs={false}
      swipeable={false}
      className="w-full md:my-12 testimonials-slider-container"
    >
      {testimonials.map((item, index) => (
        <TestimonialItem key={index} {...item} />
      ))}
    </Carousel>
  );
};

export default TestimonialSlider;
