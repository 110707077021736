import Navbar from "../landing-page/_components/navbar";
import Footer from "../landing-page/_components/footer";

import { SocialMedia } from "../../components";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full p-0 m-0 bg-white overflow-x-hidden rtl:text-right">
      <div className="w-full h-screen flex flex-col justify-start items-center overflow-x-hidden">
        <div className=" relative flex flex-col justify-center items-center w-full p-6 md:p-0">
          <Navbar />
        </div>
        <div className="container mx-auto h-fit p-6 md:px-0 md:py-12 space-y-4">
          <h1 className="text-2xl md:text-3xl font-bold">
            {t("privacy_policy.termsConditionsTitle")}
          </h1>

          <ol className="space-y-4">
            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.objectTitle")}
              </h2>
              <p>{t("privacy_policy.objectContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.definitionsTitle")}
              </h2>
              <h3 className="text-xl font-semibold">
                {t("privacy_policy.websiteDefinition")}
              </h3>
              <p>{t("privacy_policy.websiteContent")}</p>

              <h3 className="text-xl font-semibold">
                {t("privacy_policy.userDefinition")}
              </h3>
              <p>{t("privacy_policy.userContent")}</p>

              <h3 className="text-xl font-semibold">
                {t("privacy_policy.identifierTitle")}
              </h3>
              <p>{t("privacy_policy.identifierContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.complianceTitle")}
              </h2>
              <p>{t("privacy_policy.complianceContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.dataProtectionTitle")}
              </h2>
              <p>{t("privacy_policy.dataProtectionContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.changeProvisionsTitle")}
              </h2>
              <p>{t("privacy_policy.changeProvisionsContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.pricingTitle")}
              </h2>
              <p>{t("privacy_policy.pricingContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.legalMentionsTitle")}
              </h2>
              <p>{t("privacy_policy.legalMentionsContent")}</p>
            </li>

            <li>
              <h2 className="text-2xl font-semibold">
                {t("privacy_policy.contactTitle")}
              </h2>
              <p></p>
              <p>
                {t("privacy_policy.contactContent")}
                <br />
                {t("privacy_policy.mail")} :{" "}
                <a
                  href="mailto:contact@efawtara.com"
                  className="text-lg font-semibold"
                >
                  contact@efawtara.com
                </a>
                <br />
                {t("privacy_policy.phone")} :{" "}
                <a href="tel:+213770774999" className="text-lg font-semibold">
                  0770 77 49 99
                </a>
                <br />
                {t("privacy_policy.social_media")} :
                <br />
                <SocialMedia />
              </p>
            </li>
          </ol>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export { PrivacyPolicy };
