import { Accordion } from "../../../components/";
import { useSiteConfig } from "../../../config/site-config";

import { useState } from "react";

import illustration1 from "../../../assets/images/illustrations/1.png";
import illustration2 from "../../../assets/images/illustrations/2.png";
import illustration3 from "../../../assets/images/illustrations/3.png";
import illustration4 from "../../../assets/images/illustrations/4.png";
import illustration5 from "../../../assets/images/illustrations/5.png";

const Features = () => {
  const { siteConfig } = useSiteConfig();
  const data = siteConfig.features;
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? 0 : index);
  };

  const illustrations = [
    illustration1,
    illustration2,
    illustration3,
    illustration4,
    illustration5,
  ];

  return (
    <div
      className="w-full container bg-white flex flex-col justify-center items-center my-20"
      id="about"
      data-scroll
    >
      <div className="flex flex-col items-center justify-center p-6 py-12 text-center space-y-3 text-xs md:max-w-screen-lg">
        <h2 className="text-secondary md:text-lg">{data.slogan}</h2>
        <h3 className="text-secondary font-bold text-xl md:text-2xl lg:text-4xl">
          {data.title}
        </h3>
        <p className="text-gray-500 font-normal text-base lg:text-lg">
          {data.description1} {data.description2} {data.description3}
        </p>
      </div>

      <div className="w-full flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:items-center lg:justify-evenly">
        <div className="w-full xl:w-1/2 flex flow-row justify-center lg:justify-end items-center">
          <img
            alt=" "
            src={illustrations[openIndex]}
            className="max-w-[15rem] lg:max-w-lg"
          />
        </div>
        <div className="w-full xl:w-1/2 flex flex-col items-center justify-center space-y-4">
          {data?.features?.map((item, index) => (
            <Accordion
              key={index}
              title={`${index + 1} - ${item.title}`}
              content={item.description}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
