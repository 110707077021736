import { useSiteConfig } from "../../../config/site-config";
import { useState } from "react";
import FaqItem from "./faq-item";

const Faq = () => {
  const { siteConfig } = useSiteConfig();
  const data = siteConfig.faq;

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full bg-white flex justify-center items-center py-12 md:py-32">
      <div className="container flex flex-col items-center justify-center space-y-8">
        <h3 className="text-primary font-bold text-xl md:text-2xl lg:text-4xl mb-4">
          {data.title}
        </h3>

        <div className="w-full flex flex-col items-center justify-center space-y-3 mt-4">
          {data?.items?.map((item, index) => (
            <FaqItem
              key={index}
              title={item.question}
              content={item.response}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
