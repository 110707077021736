import Contact from "./_components/contact";
import Faq from "./_components/faq";
import Features from "./_components/features";
import Footer from "./_components/footer";
import Hero from "./_components/hero";
import Navbar from "./_components/navbar";
import Partners from "./_components/partners";
import Subscriptions from "./_components/subscriptions";
import Testimonials from "./_components/testimonials";

const LandingPage = () => {
  return (
    <div className="w-full h-full p-0 m-0 bg-white overflow-x-hidden rtl:text-right">
      <div className="w-full h-screen flex flex-col justify-start items-center overflow-x-hidden">
        <div className="bg-gray-light relative flex flex-col justify-center items-center w-full p-6 md:p-0">
          <Navbar />
          <Hero />
        </div>
        <Partners />
        <Features />
        <Subscriptions />
        <Testimonials />
        <Faq />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export { LandingPage };
