import { Link } from "react-router-dom";
import EfawtaraIcon from "../../../assets/logos/icone-efawtara2.png";
import EfawtaraIconAr from "../../../assets/logos/icone-efawtara2.png";
import { useSiteConfig } from "../../../config/site-config";
import { useTranslation } from "react-i18next";
import { SocialMedia } from "../../../components";
import CountrySelect from "./country-select";
import ProductOwner from "./product-owner";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";

const Footer = () => {
  const { siteConfig } = useSiteConfig();

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  return (
    <footer className="w-full py-8 bg-secondary">
      <div className="container mx-auto px-4 text-white md:px-8">
        <div className="sm:max-w-xl sm:mx-auto text-center space-y-4 flex flex-col items-center justify-center">
          <img
            src={EfawtaraIcon}
            className="w-28 sm:mx-auto hidden ltr:block"
          />
          <img
            src={EfawtaraIconAr}
            className="w-28 sm:mx-auto hidden rtl:block"
          />

          <p className="text-white font-bold text-xl md:text-2xl lg:text-4xl">
            {siteConfig.footer.title}
          </p>
          <p className="text-white font-normal text-base lg:text-lg">
            {siteConfig.footer.description}
          </p>
          <div className="items-center gap-x-3 space-y-3 sm:flex sm:justify-center sm:space-y-0">
            <a
              href="https://app.efawtara.com/company/register"
              target="_blank"
              rel="noreferrer"
              className="relative flex items-center justify-center gap-x-2 py-2 px-4 text-white font-medium border rounded-lg md:inline-flex group overflow-hidden"
            >
              <span className="relative z-10 text-white group-hover:text-secondary transition-colors duration-300">
                {siteConfig.footer.cta}
              </span>

              <ArrowLongRightIcon className="w-5 h-5 z-10 relative rtl:rotate-180 transition-colors duration-300 group-hover:text-secondary" />

              <div className="absolute inset-0 w-full h-full bg-white transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></div>
            </a>
          </div>
        </div>
        <div className="mt-10 py-4 border-t flex flex-col md:flex-row items-center justify-between">
          <p>
            {t("footer.copyrights", {
              date: currentYear,
            })}
          </p>
          <ul className="flex flex-wrap items-center gap-4 mt-6 sm:text-sm sm:mt-0">
            {siteConfig.footer.links.map((item, idx) => (
              <li className="text-white duration-150" key={idx}>
                <Link key={idx} to={item.href}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 xs:col-span-6 md:col-span-4 flex md:justify-start md:items-start justify-center items-center xs:order-1">
            <CountrySelect />
          </div>
          <div className="col-span-12 md:col-span-4 flex md:justify-center md:items-center justify-center items-center order-3 md:order-2">
            <ProductOwner />
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-4 flex md:justify-end md:items-end justify-center items-center order-2 md:order-3">
            <SocialMedia />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
