// import GoVivaLogo from "../../../assets/images/partners/goviva.png";
// import PorteMostaganemLogo from "../../../assets/images/partners/port-mostaganem.png";
// import HeetchLogo from "../../../assets/images/partners/heetch.png";
// import GuiddiniLogo from "../../../assets/images/partners/guiddini.png";
// import IfidasLogo from "../../../assets/images/partners/ifidas.png";
// import LocalNotaireLogo from "../../../assets/images/partners/localnotaire.png";

// export const PartnersData = [
//   {
//     title: "Go Viva",
//     logo: GoVivaLogo,
//     link: "https://www.govivapay.com/index.php/signin",
//   },
//   {
//     title: "Port Mostaganem",
//     logo: PorteMostaganemLogo,
//     link: "https://epaiement.port-mostaganem.com.dz/facture/clients/auth/register",
//   },
//   {
//     title: "Heetch",
//     logo: HeetchLogo,
//     link: "https://heetch.dz/",
//   },
//   {
//     title: "Guiddini",
//     logo: GuiddiniLogo,
//     link: "https://guiddini.com.dz/",
//   },
//   {
//     title: "Ifidas",
//     logo: IfidasLogo,
//     link: "https://app.efawtara.com/instant-payment/ifidas",
//   },
//   {
//     title: "Notaire Local",
//     logo: LocalNotaireLogo,
//     link: "https://localnotaire.com/",
//   },
// ];

// const Partners = () => {
//   return (
//     <section className="w-full flex justify-center items-center bg-gray-light p-12 py-16 overflow-hidden pt-[8rem]">
//       <div className="container flex justify-center items-center">
//         <div
//           className="flex flex-row items-center space-x-4 sm:overflow-x-hidden animate-infinite-scroll sm:animate-none"
//           aria-hidden="true"
//         >
//           {PartnersData?.map((p, i) => (
//             <a
//               href={p.link}
//               target="_blank"
//               rel="noreferrer"
//               key={i}
//               className={`w-48 cursor-pointer ${
//                 i === PartnersData.length - 1 ? "max-w-36" : "sm:max-w-60"
//               }`}
//               id={p.title}
//               title={p.title}
//             >
//               <img src={p.logo} alt={p.title} className="w-full h-full" />
//             </a>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Partners;

import GoVivaLogo from "../../../assets/images/partners/goviva.png";
import TraixtoLogo from "../../../assets/images/partners/traixtio.png";
import MytpeLogo from "../../../assets/images/partners/mytpe.png";
import GuiddiniLogo from "../../../assets/images/partners/guiddini.png";
import IfidasLogo from "../../../assets/images/partners/ifidas.png";
import MushafLogo from "../../../assets/images/partners/mushaf.png";
import PointiliLogo from "../../../assets/images/partners/pointili.png";
import LocalNotaireLogo from "../../../assets/images/partners/localnotaire.png";
import { useEffect, useState } from "react";
import { useSiteConfig } from "../../../config/site-config";

const url = process.env.REACT_APP_BASE_URL;

export const PartnersData = [
  {
    title: "Go Viva",
    logo: GoVivaLogo,
    link: "https://app.efawtara.com/goviva",
  },
  {
    title: "Traixto",
    logo: TraixtoLogo,
    link: "https://app.efawtara.com/traixto",
  },
  {
    title: "Mytpe",
    logo: MytpeLogo,
    link: "https://app.efawtara.com/mytpe",
  },
  {
    title: "Guiddini",
    logo: GuiddiniLogo,
    link: "https://app.efawtara.com/guiddini",
  },
  {
    title: "Ifidas",
    logo: IfidasLogo,
    link: "https://app.efawtara.com/ifidas",
  },
  {
    title: "Mushaf Zihni",
    logo: MushafLogo,
    link: "https://app.efawtara.com/mishaf-dihni",
  },
  {
    title: "Pointili",
    logo: PointiliLogo,
    link: "https://app.efawtara.com/pointili",
  },
];

const Partners = () => {
  // const [partners, setPartners] = useState<{ name: string; logo: string }[]>();

  // useEffect(() => {
  //   const fecthPartners = async () => {
  //     try {
  //       const res = await fetch(`${url}api/entities/public`);
  //       const result = await res.json();
  //       setPartners(result);
  //     } catch (error) {
  //       console.log("error fetching : ", error);
  //     }
  //   };
  //   fecthPartners();
  // }, []);

  // const entityLink = (name: string) => `${url}${name.replace(/-/g, " ")}`;

  // const entityLogo = (logo: string) => `${url}storage/public/${logo}`;

  const { siteConfig } = useSiteConfig();
  const data = siteConfig.spontaneousPayment;

  return (
    <section className="w-full flex flex-col justify-center items-center p-12 overflow-hidden py-[14rem]">
      <div className="flex flex-col items-center justify-center p-6 py-12 text-center space-y-3 text-xs md:max-w-screen-lg">
        {/* <h2 className="text-secondary md:text-lg">{data.slogan}</h2> */}
        <h3 className="text-secondary font-bold text-xl md:text-2xl lg:text-4xl">
          {data.title}
        </h3>
        <p className="text-gray-500 font-normal text-base lg:text-lg">
          {data.description}
        </p>
      </div>
      <div className="container flex justify-center items-center">
        <div
          className="flex flex-row items-center sm:overflow-x-hidden animate-marquee [--duration:13s] hover:[animation-play-state:paused] sm:animate-none gap-x-6"
          aria-hidden="true"
        >
          {/* {partners?.map((item, index) => {
            return (
              <a
                href={entityLink(item.name)}
                target="_blank"
                rel="noreferrer"
                key={index}
                className={`w-48 cursor-pointer ${
                  index === PartnersData.length - 1 ? "max-w-36" : "sm:max-w-60"
                }`}
                id={item.name}
                title={item.name}
              >
                <img
                  src={entityLogo(item.logo)}
                  alt={item.name}
                  className="lg:h-36 lg:w-36 object-contain overflow-hidden"
                />
              </a>
            );
          })} */}

          {PartnersData?.map((p, i) => (
            <a
              href={p.link}
              target="_blank"
              rel="noreferrer"
              key={i}
              className={`w-48 cursor-pointer ${
                i === PartnersData.length - 1 ? "max-w-36" : "sm:max-w-60"
              }`}
              id={p.title}
              title={p.title}
            >
              <img src={p.logo} alt={p.title} className="w-full h-full" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
