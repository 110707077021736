import { cn } from "../../../lib/utils";
import { motion } from "framer-motion";
import useIsMobile from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import SatimLogo from "../../../assets/logos/satim.png";
import GIELogo from "../../../assets/logos/gie-monetique-logo.png";

const PricingCard = ({
  index,
  item,
  type,
  hoveredIndex,
  setHoveredIndex,
}: {
  item: {
    title: string;
    price: string;
    promo_price: string;
    currency: string;
    billingCycle: string | null;
    props: string[];
    showPromo: boolean;
    hint?: string;
  };
  index: number;
  type: string;
  hoveredIndex: number | null;
  setHoveredIndex: (index: number | null) => void;
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <motion.div
      key={String(isMobile)}
      className={cn(
        "h-[450px] w-5/6 md:h-fit md:min-h-[27rem] md:w-[30%] xl:w-1/4 2xl:w-1/4 p-8 md:p-12 bg-white rounded-md flex flex-col items-center justify-between relative",
        isMobile
          ? "opacity-100 cursor-pointer"
          : type === "hybrid"
          ? "opacity-50 hover:opacity-100 hover:cursor-pointer"
          : "",
        index === 1 && hoveredIndex === null
          ? "shadow-lg opacity-100"
          : "shadow-md "
      )}
      onHoverStart={() => setHoveredIndex(index)}
      onHoverEnd={() => setHoveredIndex(null)}
      whileHover={{ scale: 1.25, zIndex: 50 }}
      animate={
        isMobile
          ? {}
          : hoveredIndex !== null && hoveredIndex !== 1 && index === 1
          ? { scale: 1, zIndex: 1 }
          : hoveredIndex === null && index === 1
          ? { scale: 1.25, zIndex: 10 }
          : {}
      }
      initial={!isMobile && index === 1 ? { scale: 1.25, zIndex: 10 } : {}}
      transition={{ duration: 0.4 }}
    >
      {item.showPromo && (
        <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
          <span className="text-center text-accent font-black">50%</span>
        </div>
      )}
      <h1 className="text-accent text-2xl font-black m-0">{item.title}</h1>
      <p className="text-accent mt-2 font-medium text-lg text-center m-0 flex flex-col items-center justify-center">
        <span
          className={cn(
            "ltr:hidden rtl:flex",
            item.promo_price ? "oblique-line-through" : ""
          )}
        >
          {item.price?.replace(/\s+/g, "")} {item.currency}
        </span>

        <span
          className={cn(
            "rtl:hidden ltr:flex",
            item.promo_price ? "oblique-line-through" : ""
          )}
        >
          {item.price} {item.currency}
        </span>

        <span className={cn("ltr:hidden rtl:flex")}>
          {item.promo_price?.replace(/\s+/g, "")} {item.currency}
          {item.billingCycle && ` / ${item.billingCycle}`}
        </span>

        <span className={cn("rtl:hidden ltr:flex")}>
          {item.promo_price} {item.currency} / {item.billingCycle}
        </span>
      </p>
      <span className="h-2 w-1/5 bg-accent rounded-full" />

      <div className="flex flex-col items-center justify-center space-y-3">
        {item?.props?.map((prop, index) => (
          <p key={index} className="text-gray-dark text-xs text-center">
            {prop}
          </p>
        ))}
      </div>

      {item?.hint && (
        <div className="w-full flex flex-row items-center justify-center gap-x-2">
          <p className="font-bold text-accent">{item.hint}</p>

          <a href="https://satim.dz/" target="_blank" title="Satim">
            <img src={SatimLogo} alt="" className="w-10" />
          </a>

          <a
            href="https://giemonetique.dz/"
            target="_blank"
            title="GIE Monétique"
          >
            <img src={GIELogo} alt="" className="w-6" />
          </a>
        </div>
      )}

      <a
        href="https://app.efawtara.com/company/register"
        target="_blank"
        rel="noreferrer"
        className={cn(
          "bg-accent text-white w-fit rounded-lg px-3 py-2 text-sm"
        )}
      >
        {t("subscriptions.cta")}
      </a>
    </motion.div>
  );
};

export default PricingCard;
