import { useForm } from "react-hook-form";
import { useSiteConfig } from "../../../config/site-config";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast, { Toaster } from "react-hot-toast";

const Contact = () => {
  const { siteConfig } = useSiteConfig();
  const data = siteConfig.contact;
  const { t } = useTranslation();

  type formType = yup.InferType<typeof validationSchema>;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("contact.form.validation.name.required")),
    phone: yup
      .number()
      .typeError(t("contact.form.validation.phone.invalid"))
      .required(t("contact.form.validation.phone.required"))
      .min(4, t("contact.form.validation.phone.invalid")),
    email: yup
      .string()
      .email(t("contact.form.validation.email.invalid"))
      .required(t("contact.form.validation.email.required")),
    subject: yup
      .string()
      .required(t("contact.form.validation.subject.required")),
    message: yup
      .string()
      .required(t("contact.form.validation.message.required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formType>({
    resolver: yupResolver(validationSchema),
  });

  const serviceID = process.env.REACT_APP_SERVICE_ID || "";
  const templateID = process.env.REACT_APP_TEMPLATE_ID || "";
  const publicKey = process.env.REACT_APP_PUBLIC_KEY || "";

  const sendMail = async (data: formType) => {
    const form: any = {
      from_name: data?.name,
      from_email: data?.email,
      message: data?.message,
      subject: data?.subject,
      phone: data?.phone,
    };
    emailjs.send(serviceID, templateID, form, publicKey).then(
      () => {
        console.log("SUCCESS!");
        toast.success("Success!");
      },
      (error) => {
        console.log("FAILED...", error);
        toast.error("Success!");
      }
    );
  };

  return (
    <div
      className="w-full bg-accent flex justify-center items-center p-12 py-20 md:py-32 text-center"
      id="contact"
    >
      <Toaster />
      <div className="container flex flex-col items-center justify-center space-y-4 md:space-y-8">
        <h3 className="text-primary font-bold text-xl md:text-2xl lg:text-4xl">
          {data.title}
        </h3>
        <h6 className="text-white font-normal text-base lg:text-lg md:max-w-screen-lg">
          {data.description}
        </h6>

        <div className="w-full flex flex-col md:flex-row items-center justify-center md:items-start md:justify-evenly">
          <form
            onSubmit={handleSubmit(sendMail)}
            className="w-full md:w-2/3 flex flex-row flex-wrap items-start justify-between text-white gap-y-4"
          >
            <div className="flex flex-col items-start space-y-2 w-full md:w-[49%]">
              <input
                type="text"
                className="outline-none focus:outline-none bg-transparent p-4 border border-white placeholder:text-white w-full"
                placeholder={data.form.name}
                {...register("name")}
              />
              <p className="text-primary font-bold">
                {errors.name && errors.name.message}
              </p>
            </div>

            <div className="flex flex-col items-start space-y-2 w-full md:w-[49%]">
              <input
                type="text"
                className="outline-none focus:outline-none bg-transparent p-4 border border-white placeholder:text-white w-full"
                placeholder={data.form.phone}
                {...register("phone")}
              />
              <p className="text-primary font-bold">
                {errors.phone && errors.phone.message}
              </p>
            </div>

            <div className="flex flex-col items-start space-y-2 w-full md:w-[49%]">
              <input
                type="email"
                className="outline-none focus:outline-none bg-transparent p-4 border border-white placeholder:text-white w-full"
                placeholder={data.form.email}
                {...register("email")}
              />
              <p className="text-primary font-bold">
                {errors.email && errors.email.message}
              </p>
            </div>

            <div className="flex flex-col items-start space-y-2 w-full md:w-[49%]">
              <input
                type="text"
                className="outline-none focus:outline-none bg-transparent p-4 border border-white placeholder:text-white w-full"
                placeholder={data.form.subject}
                {...register("subject")}
              />
              <p className="text-primary font-bold">
                {errors.subject && errors.subject.message}
              </p>
            </div>

            <div className="flex flex-col items-start space-y-2 w-full">
              <textarea
                className="outline-none focus:outline-none bg-transparent p-4 border border-white placeholder:text-white w-full"
                placeholder={data.form.message}
                {...register("message")}
              />
              <p className="text-primary font-bold">
                {errors.message && errors.message.message}
              </p>
            </div>

            <button
              type="submit"
              className="bg-primary text-secondary uppercase rounded-md py-3 px-6 text-xs font-bold mx-auto"
            >
              {t("footer.links.section_4.cta")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
