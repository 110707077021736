import { Trans } from "react-i18next";
import GuiddiniLogo from "../../../assets/logos/logo-guiddini-fr.png";

const ProductOwner = () => {
  return (
    <div className="w-fit flex flex-row items-center justify-center space-x-2 ">
      <p>
        <Trans
          i18nKey="footer.product_by"
          components={{
            a: (
              <a
                href="https://guiddini.com.dz/"
                target="_blank"
                rel="noopener noreferrer"
                className=" font-bold text-lg cursor-pointer"
              />
            ),
          }}
        />
      </p>
      <img
        src={GuiddiniLogo}
        className="w-12"
        alt="Guiddini Product owner of eFawtara"
      />
    </div>
  );
};

export default ProductOwner;
