import FacebookLogo from "../../assets/icons/facebook.png";
import instaLogo from "../../assets/icons/instagram.png";
import LinkedInLogo from "../../assets/icons/linkedin.png";

export const SocialMedia = () => {
  return (
    <div className="w-fit flex flex-row items-center justify-start space-x-3 rtl:space-x-0 rtl:gap-x-3 my-2">
      <a
        href="https://www.facebook.com/efawtara"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={FacebookLogo}
          alt="facebook"
          className="w-6 h-6 lg:h-8 lg:w-8"
        />
      </a>
      <a
        href="https://www.instagram.com/efawtara"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={instaLogo}
          alt="instagram"
          className="w-6 h-6 lg:h-8 lg:w-8"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/efawtara/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={LinkedInLogo}
          alt="LinkedIn"
          className="w-6 h-6 lg:h-8 lg:w-8"
        />
      </a>
    </div>
  );
};
