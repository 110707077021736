import { StarIcon } from "@heroicons/react/24/solid";

const TestimonialItem = (item: {
  title: string;
  author: string;
  position: string;
  logo: string;
}) => {
  return (
    <div className="w-full flex flex-col md:flex-row items-center justify-center md:justify-evenly select-none space-y-8 md:space-y-0 ">
      <div className="w-11/12 md:w-3/4 order-2 md:order-1 mt-6 md:mt-0">
        <div className="w-full md:w-3/4 bg-gray-light rounded-3xl rounded-br-none p-6 flex flex-col items-start justify-evenly md:min-h-[14rem]">
          <div className="w-full flex flex-row rtl:flex-row-reverse items-center justify-start">
            <StarIcon className="w-4 h-4 text-primary" />
            <StarIcon className="w-4 h-4 text-primary" />
            <StarIcon className="w-4 h-4 text-primary" />
            <StarIcon className="w-4 h-4 text-primary" />
            <StarIcon className="w-4 h-4 text-primary" />
            <span className="text-gray-dark font-bold ml-3">(5)</span>
          </div>

          <p className="text-gray-400 font-medium text-left rtl:text-right w-full">
            {item.title}
          </p>
          <p className="text-black font-semibold text-left rtl:text-right w-full">
            {" "}
            {item.author}
          </p>
          <p className="text-gray-400 text-left rtl:text-right w-full">
            {item.position}
          </p>
        </div>
      </div>
      <div className="w-full h-44 md:w-1/4 order-1 md:order-2">
        <img src={item.logo} alt="" className="w-full h-full object-none" />
      </div>
    </div>
  );
};

export default TestimonialItem;
