import { useTranslation } from "react-i18next";
import { useSiteConfig } from "../../../config/site-config";

import TestimonialSlider from "./testimonial-slider";

const Testimonials = () => {
  const { siteConfig } = useSiteConfig();
  const data = siteConfig.testimonials;

  const { i18n } = useTranslation();
  const dir = i18n.dir();

  return (
    <div className="w-full bg-secondary flex justify-center items-center p-8 md:p-20 md:min-h-[40rem]">
      <div className="container direct" dir={dir}>
        <div className=" flex flex-col justify-center items-center md:space-y-4 text-center">
          <h3 className="text-primary font-bold text-xl md:text-2xl lg:text-4xl">
            {data.title}
          </h3>
          <p className="text-white font-normal text-base lg:text-lg">
            {data.description1}
            <br />
            {data.description2}
          </p>
        </div>
        <TestimonialSlider testimonials={data?.list} />
      </div>
    </div>
  );
};

export default Testimonials;
