import { useSiteConfig } from "../../../config/site-config";

import EfawtaraOverviewImage from "../../../assets/images/image-header.png";
import EfawtaraOverviewARImage from "../../../assets/images/image-header-ar.png";
import { useEffect, useRef, useState } from "react";
import {
  CircleStackIcon,
  PlayCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

type Handler = () => void;

let useClickOutside = (handler: Handler) => {
  // Define the type for domNode
  let domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let maybeHandler = (event: MouseEvent) => {
      // Ensure domNode.current is not null before accessing contains method
      if (domNode.current && !domNode.current.contains(event.target as Node)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  }, [handler]); // Add handler to the dependency array

  return domNode;
};

const Hero = () => {
  const { siteConfig } = useSiteConfig();

  const [videoOpen, setvideoOpen] = useState(false);

  let domNode = useClickOutside(() => {
    setvideoOpen(false);
  });

  return (
    <div className="container w-full h-fit flex flex-col items-center justify-center md:grid md:grid-cols-12 md:py-6 lg:py-12 xl:py-20 md:min-h-[35rem]">
      {/* Text / description / CTA */}
      <div className="md:col-span-5 h-full relative md:z-10 py-8 px-6">
        <div className="w-full h-full flex flex-col items-start justify-start md:justify-center space-y-3">
          {/* Title */}

          <h1 className="font-bold text-secondary text-center md:text-start text-xl sm:text-4xl md:text-2xl lg:text-3xl xl:text-4xl order-1 ">
            {siteConfig.hero.title}
          </h1>

          {/* Description */}

          <h3 className="w-full font-normal text-black text-sm sm:text-base md:text-xs lg:text-lg order-2 text-center md:text-start">
            {siteConfig.hero.description}
          </h3>

          {/* CTA */}
          <div className="w-full flex flex-col space-y-4 sm:space-y-0 sm:flex-row items-center justify-center md:justify-normal gap-x-2 order-4">
            <a
              href="https://app.efawtara.com/company/register"
              className="bg-secondary text-white uppercase rounded-lg p-3 text-sm font-bold rtl:text-lg text-center w-full sm:w-auto"
            >
              {siteConfig.hero.cta1.title}
            </a>
            <a
              href="#contact"
              className="bg-primary text-secondary uppercase rounded-lg p-3 text-sm font-bold rtl:text-lg text-center w-full sm:w-auto"
            >
              {siteConfig.hero.cta2.title}
            </a>
          </div>
        </div>
      </div>

      {/* Hero Image */}
      <div className="md:col-span-7 h-full md:z-10 order-3">
        <div className="w-full h-full md:bg-primary md:absolute md:top-0 ltr:md:rounded-bl-[15rem] rtl:md:rounded-br-[15rem] md:flex md:items-end ltr:md:justify-start">
          <img
            src={EfawtaraOverviewImage}
            alt="Efawtara overview"
            className="md:max-w-[60rem] lg:max-w-[65rem] md:relative md:-bottom-[3rem] rtl:hidden ltr:block"
          />
          <img
            src={EfawtaraOverviewARImage}
            alt="Efawtara overview"
            className="md:max-w-[70rem] lg:max-w-[75rem] md:relative md:-bottom-[3rem] ltr:hidden rtl:block"
          />
        </div>

        <div className="w-full h-full relative">
          <div
            ref={domNode}
            className={`absolute left-0 -top-[70%] md:top-0 z-30 flex h-full w-full items-center justify-center`}
          >
            <span
              onClick={() => setvideoOpen(true)}
              className="absolute cursor-pointer z-50 flex h-20 w-20 items-center justify-center rounded-full bg-white text-primary dark:bg-dark-2 dark:text-white md:h-[100px] md:w-[100px]"
            >
              <span className="absolute right-0 top-0 z-[-1] h-full w-full animate-ping rounded-full bg-white bg-opacity-70 delay-300 duration-1000"></span>
              <PlayCircleIcon className="w-20 h-20 text-primary" />
            </span>
          </div>
        </div>
      </div>

      {videoOpen && (
        <div className="h-screen w-screen fixed top-0 left-0 z-50 no-doc-scroll items-center justify-center bg-black bg-opacity-70">
          <div className="mx-auto w-full h-full max-w-[800px] flex justify-center items-center">
            {/* <iframe
             
              src="https://www.youtube.com/embed/V42lEWuNqOc?si=itAeGUTXf2RYIOy9"
            /> */}
            <iframe
              className="h-[320px] w-2/3 lg:h-[450px] lg:w-full"
              src="https://www.youtube.com/embed/-deW8UCAhuE?si=uANWv2PBQ4tSLTGf"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>

          <button
            onClick={() => setvideoOpen(false)}
            className="absolute right-0 top-0 flex h-20 w-20 cursor-pointer items-center justify-center text-body-color"
          >
            <XMarkIcon className="h-10 w-10" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Hero;
