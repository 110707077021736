import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../../../lib/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

interface FaqItemProps {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}

const FaqItem: React.FC<FaqItemProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      className={cn(
        "w-5/6 md:w-11/12 flex flex-col items-center justify-center cursor-pointer p-6 border border-accent/50"
      )}
      onClick={onToggle}
    >
      <div className="w-full flex flex-row items-center justify-between gap-x-2">
        <h3 className={cn("text-lg md:text-xl font-semibold text-accent")}>
          {title}
        </h3>
        {!isOpen ? (
          <ChevronDownIcon className="text-accent w-6 h-6" />
        ) : (
          <ChevronUpIcon className="text-accent w-6 h-6" />
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="w-full mt-6"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0, margin: 0 },
            }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-accent text-base text-start mt-6">{content}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FaqItem;
